<template>
  <div>
    <sms-tpl></sms-tpl>
  </div>
</template>

<script>
import smsTpl from "./components/message/tpl.vue";
export default {
  components: {
    smsTpl,
  },
  data() {
    return {
      type: "tpl",
    };
  },
  created() {},
  methods: {
    callback(e) {},
  },
};
</script>

<style></style>
